<template>
  <div>
    <p>404</p>

    <router-link :to="{name: 'home'}">Back to Home</router-link>
  </div>
</template>

<script>
export default {
  name: "Error404"
}
</script>

<style scoped>

</style>
